import { TileIdentType } from '..';

export interface AppHeader {
  [k: string]: number | boolean | string | HeaderMenuItem[] | AppHeaderOrg | HeaderBuildInfo;

  assignOrganisationVisible: boolean;
  buildInformation: HeaderBuildInfo;
  homeUrl: string;
  menus: HeaderMenuItem[];
  mySettingsVisible: boolean;
  openTasks: number;
  openTickets: number;
  organisation: AppHeaderOrg;
  searchVisible: boolean;
  switchVisible: boolean;
  locale: string;
}

export interface AppHeaderOrg {
  bitSightApiTokenConfigured: boolean;
  bitSightMonitoringFolder: string;
  compliance: boolean;
  events: boolean;
  isTSpecial: boolean;
  logo: boolean;
  menuColour: string;
  multiplePci: boolean;
  name: string;
  pciAutoRoll: boolean;
  pciCompliance: boolean;
  pciProjectKey: number;
  projects: boolean;
  role: string;
  scanning: boolean;
  setup: AppHeaderOrgSetup;
  singleSignon: boolean;
  textColour: string;
  userWelcomeEmailEnabled: boolean;
  wireless: boolean;
}

export interface AppHeaderOrgSetup {
  complianceTemplates: boolean;
  environment: string;
  organisation: AppHeaderSetupOrg;
  personType: string;
  role: string;
  rootOrganisation: AppHeaderSetupOrg;
  specialRoles: string[];
}

export interface AppHeaderSetupOrg {
  compliance: boolean;
  events: boolean;
  isTSpecial: boolean;
  key: number;
  multiplePci: boolean;
  pciCompliance: boolean;
  pciProjectKey: number;
  projects: boolean;
  role: string;
  scanning: boolean;
  singleSignon: boolean;
  wireless: boolean;
}

export enum HeaderMenuArea {
  IntegrationHub = 'Integration Hub',
  Settings = 'Settings'
}

export enum MenuItemName {
  SettingsIntegrations = 'Integrations'
}

export interface MenuItem {
  label?: string;
  grc?: boolean;
  security?: boolean;
  menus?: MenuItem[];
  name?: string;
  type?: TileIdentType;
  button?: MenuItemButton;
  icon?: string;
  links?: MenuItemLink[];
  id?: number;
  idString?: string;
  actions?: MenuItem[];
  url?: string;
  area?: string;
  fixed?: boolean;
  index?: number;
  initFunction?: string;
  favourited?: boolean;
  defaultFavourited?: boolean;
  homePage?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  canUnsubscribe?: boolean;
  systemAdmin?: boolean;
  organisationAdmin?: boolean;
  published?: boolean;
  canDownload?: boolean;
  canEmail?: boolean;
  visibleInMenu?: boolean;
}

export enum MenuItemButton {
  DashboardAdd = 'DASHBOARD_ADD',
  ApplicationAdd = 'APPLICATION_GROUP_ADD',
  DashboardSubscribe = 'DASHBOARD_SUBSCRIBE',
  None = '',
}

export interface MenuItemLink {
  href: string;
  rel: MenuItemLinkName;
  title?: string;
}

export enum MenuItemLinkName {
  Delete = 'delete',
  Schedule = 'schedule',
  Pdf = 'pdf',
  Unsubscribe = 'unsubscribe',
  Edit = 'edit',
  Publish = 'publish',
  TileFavourite = 'tile.favourite'
}

export type HeaderMenuItem = MenuItem;

// deprecated in favour of MenuItem
export interface LeftMenuItem {
  type: string;
  button?: any;
  icon: string;
  links: LeftMenuItemLink[];
  menus: LeftMenuItem[];
  id?: number;
}

// deprecated in favour of MenuItemLink
export interface LeftMenuItemLink {
  href: string;
  rel: string;
  title: string;
}

export interface HeaderBuildInfo {
  branch: string;
  buildDate: string;
  version: string;
}
