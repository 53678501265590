import { PrimaryNavItem } from './primary-nav-item';

export interface PrimaryNav {
  top: PrimaryNavItem[];
  bottom: PrimaryNavItem[];
}

export enum PrimaryNavLabelKey {
  Admin = 'app.admin',
  Applications = 'app.apps',
  ControlLibrary = 'app.control.library',
  Dashboards = 'app.dashboards',
  Favorites = 'app.favourites',
  Favourites = 'app.favourites',
  Help = 'app.help',
  IntegrationHub = 'app.integration.hub',
  Recents = 'app.recents',
  Support = 'app.support',
  Search = 'app.search',
}

export enum PrimaryNavName {
  Admin = 'admin',
  Applications = 'applications',
  ControlLibrary = 'control library',
  Dashboards = 'dashboards',
  Favorites = 'favorites',
  Favourites = 'favourites',
  Help = 'help',
  IntegrationHub = 'integration hub',
  None = '',
  Recents = 'recents',
  Support = 'support',
  Search = 'search',
}

export enum PrimaryNavAltName {
  Admin = 'admin',
  ApplicationGroup = 'application_group',
  CyberApplicationGroup = 'cyber_application_group',
  Dashboard = 'dashboard',
}
